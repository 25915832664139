<template>
  <div class="home-header-container" :style="{ height }">
    <div class="home-header" :style="{ height }">
      <img src="@/assets/images/pc/home/home-header.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeHeader",  
  props: {
    height: {
      type: String,
      default: "0.3333rem",
    },
  },
};
</script>

<style lang="less" scoped>
.home-header {
  position: fixed;
  width: 100%;
  z-index: 8;
  background-color: #fff;

  img {
    // width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}
</style>
